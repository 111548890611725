import React, { useEffect, useState } from 'react';
import { Item, List, RemoveIcon, Text, Textarea, Wrapper } from './GroupRadioInput.parts';
import { StyledLabel } from '../Input/Input.parts';

const OTHER = 'Other';

const SAVE_PLACE_LIST = ['Front door', 'Front gate', 'Reception', 'Back door', 'Garage door', OTHER];

type Props = {
  setValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
};

export const GroupRadioInput = ({ setValue }: Props) => {
  const [active, setActive] = useState('');
  const [textarea, setTextarea] = useState(false);
  const [textareaValue, setTextareaValue] = useState('');

  const handleClick = (text: string) => {
    setActive(text);

    text === OTHER ? setTextarea(true) : setTextarea(false);
  };

  useEffect(() => {
    setValue('deliveryNote', textarea ? textareaValue : active);
  }, [active, textareaValue]);

  return (
    <Wrapper>
      <StyledLabel>A safe place to leave your box *</StyledLabel>
      <List>
        {SAVE_PLACE_LIST.map(item => (
          <Item key={item} onClick={() => handleClick(item)} isActive={active === item}>
            {item}
            {active === item && (
              <RemoveIcon
                onClick={e => {
                  e.stopPropagation();
                  setActive('');
                }}
              />
            )}
          </Item>
        ))}
      </List>
      {textarea && (
        <Textarea
          rows={5}
          value={textareaValue}
          onChange={e => setTextareaValue(e.target.value)}
          placeholder="Let us know any delivery information here including a safe & sheltered spot to leave your box"
        />
      )}

      <Text>
        Our couriers will deliver your box between 7.00am and 5.00pm. You'll receive a tracking number so you can
        monitor its journey to you.
      </Text>
    </Wrapper>
  );
};
