import { Label, StyledInput, Container, UpBtn, DownBtn } from './Input.parts';
import React, { useState } from 'react';

type InputType = {
  amount: string;
  setAmount: (value: string) => void;
};

export const Input = ({ amount, setAmount }: InputType) => {
  const onChange = (event: React.SyntheticEvent) => {
    const target = event.target as HTMLButtonElement;
    if (target) {
      setAmount(target.value);
    }
  };

  return (
    <Container>
      <Label>Amount</Label>
      <StyledInput pattern="[0-9]*" type="number" value={amount} onInput={onChange} />
    </Container>
  );
};
