import { ISchema } from 'yup';
import * as Yup from 'yup';

export const paymentSchema = (value: boolean): ISchema<any> =>
  value
    ? Yup.object().shape({
        billingAddress: Yup.bool(),
        termPolicy: Yup.bool().oneOf([true]),
      })
    : Yup.object().shape({
        billingAddress: Yup.bool(),
        address: Yup.string().min(2, 'Too Short!').max(50, 'Too Long!').required('Required'),
        termPolicy: Yup.bool().oneOf([true]),
      });
