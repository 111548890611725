import styled from 'styled-components';
import theme from '../../../feachers/theme';
import Arrow from '../../..//assets/icons/down-arrow.svg';
import { rgba } from '../../../helpers/rgba';
export const Container = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

export const Label = styled.label`
  font-size: 18px;
  font-weight: 500;
`;
export const StyledInput = styled.input`
  width: 100%;
  max-width: 237px;
  background-color: #fff;
  padding: 10px 20px;
  border: 1px solid #333300;
  border-radius: 20px;
  color: #787d2f;
  font-size: 18px;
  // hide arrows
  -moz-appearance: textfield;
  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  @media screen and ${theme.breakpoints.mobileSm} {
    max-width: 192px;
  }
`;
const Btn = styled.button`
  position: absolute;
  right: 10px;
  width: 14px;
  height: 12px;
  padding: 0;
  background-image: url(${Arrow});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: 50% 30%;
  border: none;
  background-color: transparent;
  cursor: pointer;
  :hover {
    //opacity: 0.6;
    background-color: ${rgba('#333', 0.1)};
  }
`;
export const UpBtn = styled(Btn)`
  top: calc(50% - 11px);
  transform: translate(-50%, 0) rotate(180deg);
`;

export const DownBtn = styled(Btn)`
  bottom: calc(50% - 11px);
  transform: translate(-50%, 0);
`;
