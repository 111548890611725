import React from 'react';
import { StyledTitle } from './Title.parts';

type Props = {
  as?: React.ElementType;
  children: React.ReactNode;
};
export const Title = ({ children, ...props }: Props) => {
  return <StyledTitle {...props}>{children}</StyledTitle>;
};
