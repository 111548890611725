import React, { useEffect } from 'react';
import * as Yup from 'yup';
import { Input } from '../Input';
import { FormRow, TermsBlock, TermsBlockTitle } from './PaymentForm.parts';
import { Button } from '../Button';
import { Field, Form, Formik } from 'formik';
import { CheckboxFormik } from '../Checkbox/Checkbox';
import { AnyObject, ISchema } from 'yup';
import { postCheckout } from './PaymentForm.helpers';
import { formattedProduct } from '../OrderSummary/OrderSummary.helpers';
import { useSelector } from 'react-redux';
import { selectorCart } from '../../store/cart/cart.selectors';
import { selectorDeliveryInfo, selectorUserInfo } from '../../store/user/user.selectors';
import { paymentSchema } from './PaymentForm.validation';
import { InitialValuesTypes } from './PaymentForm.types';
import { useScrollTop } from '../../hooks/useScrollTop';
import ReactGA from 'react-ga4';
import { ReactGAOptions } from '../../constants';
import ReactPixel from 'react-facebook-pixel';

const initialValues: InitialValuesTypes = {
  billingAddress: true,
  address: '',
  termPolicy: false,
};

export const PaymentForm = () => {
  const { products, address, discounts } = useSelector(selectorCart);
  const { email, password } = useSelector(selectorUserInfo);
  const deliveryInfo = useSelector(selectorDeliveryInfo);

  useScrollTop();

  useEffect(() => {
    ReactGA.send({ ...ReactGAOptions, title: 'Checkout: Payment Details' });
    ReactPixel.track('ViewContent', { content_name: 'Checkout: Payment Details' });
  }, []);

  const createCheckout = async (value: InitialValuesTypes) => {
    const billingAddress = value.billingAddress ? address.address : value.address;
    const data = {
      products: formattedProduct(products),
      discounts: discounts,
      delivery_address: {
        address: address.address,
        address_id: address.nzp_address_id,
        postcode: address.postcode,
        address_nickname: deliveryInfo.addressNickname,
        company: deliveryInfo.company,
        delivery_note: deliveryInfo.deliveryNote,
      },
      email: email,
      password: password,
      first_name: deliveryInfo.firstName,
      last_name: deliveryInfo.lastName,
      phone: deliveryInfo.phone,           
      billing_address: billingAddress,
      marketing_email: deliveryInfo.marketingEmail,
      marketing_txt: deliveryInfo.marketingTxt,
    };

    const response = await postCheckout(data);

    if (response) {
      ReactGA.send({ ...ReactGAOptions, title: 'Checkout: Payment Success' });
      ReactPixel.track('ViewContent', { content_name: 'Checkout: Payment Success' });

      if (window.top) {
        window.top.location.replace(response.URL);
      } else {
        window.location.replace(response.URL);
      }
    }
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={() =>
          Yup.lazy((value: any): ISchema<any, AnyObject, any, any> => paymentSchema(value.billingAddress))
        }
        onSubmit={values => {
          void createCheckout(values);
        }}
      >
        {({ values, handleChange, dirty, isValid }) => {
          return (
            <Form>
              <FormRow>
                <Field
                  id="billingAddress"
                  label="Billing address is the same as delivery address"
                  name="billingAddress"
                  labelColor="#787D2F"
                  checked={!!values.billingAddress}
                  onChange={(e: any) => {
                    handleChange(e);
                  }}
                  component={CheckboxFormik}
                />
              </FormRow>
              {!values.billingAddress && (
                <>
                  <Field type="text" id="address" name="address" label="Address *" component={Input} />
                </>
              )}

              <TermsBlock>
                <TermsBlockTitle>
                  By signing up to Wonky Box you agree to our terms of service/privacy policy and our cookies policy
                </TermsBlockTitle>
                <Field
                  label="I have read and understood the Terms of Service/Privacy Policy and cookies policy*"
                  id="termPolicy"
                  name="termPolicy"
                  type="checkbox"
                  labelColor="#333300"
                  onChange={(e: any) => {
                    handleChange(e);
                  }}
                  component={CheckboxFormik}
                />
              </TermsBlock>
              <Button disabled={!(isValid && dirty)}>Go to payment</Button>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};
