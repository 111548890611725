import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Costs, Discounts, InitialState, ProductType, ValidAddress } from './cart.dto';

const initialState: InitialState = {
  products: [],
  address: {
    address: '',
    nzp_address_id: '',
    postcode: '',
    result: '',
  },
  discounts: [],
  costs: {
    discount: 0,
    shipping: 0,
    subtotal: 0,
    total: 0,
    rural: 0,
  },
};

const cartSlice = createSlice({
  name: 'cart',
  initialState: initialState,
  reducers: {
    addProduct(state, { payload }: PayloadAction<ProductType>) {
      state.products.push(payload);
    },
    removeProduct(state, { payload }: PayloadAction<{ id: number }>) {
      state.products = state.products.filter(item => item.product_variant_id !== payload.id);
    },
    addValidAddress(state, { payload }: PayloadAction<ValidAddress>) {
      state.address = { ...payload };
    },
    addDiscount(state, { payload }: PayloadAction<Discounts>) {
      if (!state.discounts.find(item => item.code === payload.code)) {
        state.discounts = [{ code: payload.code, name: payload.name }, ...state.discounts];
      }
    },
    removeDiscount(state, { payload }: PayloadAction<string>) {
      state.discounts = state.discounts.filter(item => item.code !== payload);
    },
    addCosts(state, { payload }: PayloadAction<Costs>) {
      state.costs = payload;
    },
  },
});

export const { addProduct, removeProduct, addValidAddress, removeDiscount, addDiscount, addCosts } = cartSlice.actions;

export const cartReducer = cartSlice.reducer;
