import { Label, Container, StyledSelect } from './Select.parts';
import type { SelectTypes } from './Select.types';

export const Select = ({ label, options, setActive }: SelectTypes) => {
  return (
    <Container>
      <Label>{label}</Label>
      <StyledSelect name="select" onChange={e => setActive(Number(e.target.value))}>
        {options.map(item => (
          <option value={item.frequency_id}>{item.name}</option>
        ))}
      </StyledSelect>
    </Container>
  );
};
