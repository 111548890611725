import styled from 'styled-components';
import theme from '../../feachers/theme';
import { ModalContentWrap } from '../../components/Modal/Modal.parts';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: auto;
  max-width: 900px;
  padding: 0 10px;
  text-align: center;
`;

export const FormContainer = styled.form`
  position: relative;
  display: flex;
  width: 100%;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;

  @media screen and ${theme.breakpoints.mobile} {
    flex-direction: column;
    padding-top: 0;
  }
`;

export const SuggestionsList = styled.div`
  position: absolute;
  left: 0;
  top: 73px;
  width: calc(100% - 156px);
  text-align: left;
  font-size: 18px;
  font-family: inherit;
  color: #333333;
  vertical-align: middle;
  background-color: #ffffff;
  border: 1px solid rgba(135, 138, 62, 0.5);
  border-radius: 28px;
  overflow: hidden;

  @media screen and ${theme.breakpoints.mobile} {
    width: 100%;
    margin-right: 0;
    margin-bottom: 20px;
    font-size: 15px;
  }
`;

export const SuggestionsItem = styled.span`
  display: block;
  padding: 8px 40px;
  line-height: 1.42857143;
  border-bottom: 1px solid #787d2f;
  transition: 0.3s;
  &:last-child {
    border-bottom: none;
  }
  &:hover {
    background-color: rgba(135, 138, 62, 0.3);
    cursor: pointer;
  }
`;

export const Content = styled.div`
  max-width: 710px;
  padding-bottom: 70px;
`;

export const ImgContainer = styled.div`
  width: 161px;
  height: 191px;
  img {
    width: 100%;
    height: 100%;
  }

  @media screen and ${theme.breakpoints.mobile} {
    display: none;
  }
`;

export const ModalContent = styled(ModalContentWrap)`
  width: 100%;
`;

export const ImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  max-width: 147px;
  padding-top: 30px;
  margin: auto;
`;
