import styled from 'styled-components';
import theme from '../../feachers/theme';

export const StyledTitle = styled.h1`
  text-align: center;
  font-size: 38px;
  color: #787d2f;
  font-weight: 400;
  font-family: 'Mix Pudgy Panda';
  text-transform: uppercase;
  letter-spacing: 1.5px;
  padding-bottom: 10px;

  @media screen and ${theme.breakpoints.mobileSm} {
    font-size: 32px;
  }
`;
