import { Field, Form, Formik, useFormik } from 'formik';
import { Input } from '../Input';
import { Button } from '../Button';
import React from 'react';
import { ButtonWrap, StyledForm } from './WaitingListForm.parts';
import { AddToWaitingList, WaitingListFormTypes } from './WaitingListForm.types';
import { WaitingListSchema } from './WaitingListForm.validate';
import { addToWaitingList } from './WaitingListForm.helpers';

export const WaitingListForm = ({ closeModal, setThirdModal }: WaitingListFormTypes) => {
  const submitHandler = async ({ email, postcode, name }: AddToWaitingList) => {
    const res = await addToWaitingList({ email, postcode, name });

    if (res) {
      closeModal();
      setThirdModal(true);
    }
  };

  return (
    <Formik
      initialValues={{
        email: '',
        name: '',
        postcode: '',
      }}
      onSubmit={async values => submitHandler(values)}
      validationSchema={WaitingListSchema}
    >
      {({ isValid, values, dirty }) => {
        return (
          <>
            <StyledForm>
              <Field type="text" id="email" name="email" label="Email Address" component={Input} />
              <Field type="text" id="firstName" name="name" label="First Name " component={Input} />
              <Field type="text" id="postalCode" name="postcode" label="Postal Code *" component={Input} />
              <ButtonWrap>
                <Button disabled={!(isValid && dirty)} type={'submit'}>
                  JOIN OUR WAITING LIST
                </Button>
              </ButtonWrap>
            </StyledForm>
          </>
        );
      }}
    </Formik>
  );
};
