import styled from 'styled-components';
import theme from '../../feachers/theme';

type ButtonProps = {
  filled?: boolean;
  loading: boolean;
};
export const StylesButton = styled.button<ButtonProps>`
  height: 55px;
  padding: 12px 30px;
  border-radius: 40px;
  border: 2px solid ${({ filled }) => (filled ? ' transparent' : 'var(--color-orange)')};
  text-transform: uppercase;
  color: var(--color-grey-01);
  font-weight: var(--typeweight--default);
  font-family: inherit;
  font-size: var(--typesize--medium);
  line-height: 1.4;
  background-color: ${({ filled }) => (filled ? 'var(--color-orange)' : 'transparent')};
  cursor: pointer;
  &:disabled {
    pointer-events: none;
    opacity: 0.5;
  }
  //&:after {
  //  content: '';
  //  position: absolute;
  //  left: 0;
  //  top: 0;
  //  width: 100%;
  //  height: 100%;
  //
  //}
  @media screen and ${theme.breakpoints.mobile} {
    width: 100%;
    font-size: 14px;
    padding: 12px 15px;
  }
`;
