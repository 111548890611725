import { useSelector } from 'react-redux';
import { Container, FormWrap, OrderWrap } from './OrderDetails.parts';
import { DeliveryAddressForm } from '../../components/DeliveryAddressForm';
import { OrderSummary } from '../../components/OrderSummary';
import { Title } from '../../components/Title';
import { selectorStep } from '../../store/view/view.selectors';
import { InfoBox, InfoBoxIcon, InfoBoxText } from '../AccountDetails/AccountDetails.parts';
import { CreateAccountForm } from '../../components/CreateAccountForm';
import { PaymentForm } from '../../components/PaymentForm';
import { useGetWindowSize } from '../../hooks/useGetWindowSize';
import { breakpoints } from '../../feachers/theme/tokens/breakpoints';

export const OrderDetails = () => {
  const activeStep = useSelector(selectorStep);
  const { innerWidth } = useGetWindowSize();
  const showOrderSummary = innerWidth > breakpoints.screenMd || activeStep === 5;

  return (
    <Container showOrderSummary={showOrderSummary}>
      <FormWrap>
        {activeStep === 3 && (
          <>
            <Title> Account details</Title>
            <InfoBox>
              <InfoBoxText>
               With a Wonky Box account, you can manage deliveries, edit box preferences, update your details and earn rewards.
               No commitment, skip, swap and stop your deliveries in just a few clicks.
              </InfoBoxText>
              <InfoBoxIcon>No commitment: swap your box, skip deliveries or cancel anytime.</InfoBoxIcon>
            </InfoBox>
            <CreateAccountForm />
          </>
        )}
        {activeStep === 4 && (
          <>
            <Title>DELIVERY DETAILS</Title>
            <DeliveryAddressForm />
          </>
        )}
        {activeStep === 5 && (
          <>
            <Title>PAYMENT DETAILS</Title>
            <PaymentForm />
          </>
        )}
      </FormWrap>
      {showOrderSummary && (
        <OrderWrap>
          <OrderSummary />
        </OrderWrap>
      )}
    </Container>
  );
};
