import styled from 'styled-components';
import theme from '../../feachers/theme';

interface InputProps {
  error: boolean;
}

export const BaseInput = styled.input`
  display: block;
  width: 100%;
  padding: 8px 40px;
  font-size: 18px;
  line-height: 1.42857143;
  color: #333300;
  vertical-align: middle;
  background-color: #ffffff;
  border: 1px solid rgba(135, 138, 62, 0.5);
  height: 55px;
  border-radius: 40px;
  font-family: Meloso;
  :hover,
  :focus {
    box-shadow: 1px 1px 10px 0px rgba(135, 138, 62, 0.7);
    outline: none;
  }
  ::placeholder {
    color: rgba(51, 51, 0, 0.5);
  }

  ::-ms-input-placeholder {
    color: rgba(51, 51, 0, 0.3);
  }
`;

export const Container = styled.div`
  position: relative;
  margin-bottom: 20px;
`;

// export const FormikInput = styled();
export const StyledInput = styled(BaseInput)<InputProps>`
  color: ${props => (props.error ? '#EA4335' : '#333300')};
  background-color: ${props => (props.error ? 'rgba(237, 191, 164, 0.2)' : '#ffffff')};
  border: 1px solid ${props => (props.error ? '#F8F0E5' : 'rgba(135, 138, 62, 0.5)')};
`;

export const StyledLabel = styled.label`
  display: block;
  margin-bottom: 15px;
  color: #787d2f;
  font-size: 19px;
  font-weight: 500;
  line-height: 26px;
  cursor: pointer;
  @media screen and ${theme.breakpoints.mobile} {
    font-size: 16px;
  }
`;

export const StyledCheckbox = styled.input`
  background-color: #787d2f;
  color: #787d2f;
`;

export const StyledLabelCheckbox = styled.label`
  margin-bottom: 15px;
  color: #787d2f;
  font-size: 1.3em;
  font-weight: var(--typeweight--default);
  display: inline-block;
  cursor: pointer;
`;

export const ErrorMessage = styled.span`
  display: block;
  padding-top: 10px;
  color: #ea4335;
  font-size: 16px;
`;
