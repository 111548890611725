import { Img, Wrapper } from './Logo.parts';
import logoSrc from '../../assets/img/logo.png';

export const Logo = () => {
  return (
    <Wrapper>
      <Img src={logoSrc} />
    </Wrapper>
  );
};
