import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DeliveryInfo, User, UserInfo } from './user.dto';

const initialStateUserInfo = {
  email: '',
  password: '',
};

const initialStateDeliveryInfo = {
  firstName: '',
  lastName: '',
  address: '',
  postCode: '',
  phone: '',
  addressNickname: '',
  deliveryNote: '',
  company: '',
  offers: false,
  marketingEmail: false,
  marketingTxt: false,
};

const initialState: User = {
  sigIn: false,
  userInfo: initialStateUserInfo,
  deliveryInfo: initialStateDeliveryInfo,
};

const userSlice = createSlice({
  name: 'user',
  initialState: initialState,
  reducers: {
    setUserInfo(state, { payload }: PayloadAction<UserInfo>) {
      state.userInfo = payload;
    },
    setDeliveryInfo(state, { payload }: PayloadAction<DeliveryInfo>) {
      state.deliveryInfo = payload;
    },
  },
});

export const { setUserInfo, setDeliveryInfo } = userSlice.actions;

export const userReducer = userSlice.reducer;
