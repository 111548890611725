import * as Yup from 'yup';

export const SignupSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Required'),
  phone: Yup.number()
    .positive("A phone number can't start with a minus")
    .integer("A phone number can't include a decimal point")
    .min(8),
  password: Yup.string()
    .trim()
    .strict()
    .required('No password provided.')
    .min(8, 'Password is too short - should be 8 chars minimum.'),
  passwordConf: Yup.string()
    .required('No password provided.')
    .oneOf([Yup.ref('password')], 'Passwords must match'),
});
