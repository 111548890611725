import axios, { AxiosError } from 'axios';
import { API_URL } from '../../store/api/api.const';

export const validateAddress = async (value: string) => {
  try {
    const res = await axios.post(`${API_URL}/address/check/`, {
      address: value,
    });
    return res.data;
  } catch (error) {
    console.log(error);
    if (axios.isAxiosError(error) && error.response) {
      return error.response.data;
    }
  }
};

export const suggestionAddress = async (value: string) => {
  try {
    const res = await axios.post(`${API_URL}/address/suggestions/`, {
      address: value,
    });
    return res.data;
  } catch (error) {
    console.error(error);
    alert('Something went wrong. Please try again later');
    return false;
  }
};
