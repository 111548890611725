import { Formik, Field, Form } from 'formik';
import { useDispatch, useSelector } from 'react-redux';
import { Input } from '../Input';
import { Button } from '../Button';
import { ErrorMessage, StyledContainer } from './CreateAccountForm.parts';
import { setUserInfo } from '../../store/user/user.reducers';
import { SignupSchema } from './CreateAccountForm.validation';
import { selectorUserInfo } from '../../store/user/user.selectors';
import { setStep } from '../../store/view/view.reducers';
import { selectorStep } from '../../store/view/view.selectors';
import { useScrollTop } from '../../hooks/useScrollTop';
import { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { ReactGAOptions } from '../../constants';
import ReactPixel from 'react-facebook-pixel';
import { checkIfUserExist, ErrorMessageType } from './CreateAccountForm.helpers';

export const SIGN_IN_LINK = (
  <a target="_blank" href="https://account.wonkybox.nz/">
    Sign In
  </a>
);

export const CreateAccountForm = () => {
  const [userValidation, setUserValidation] = useState<ErrorMessageType>();
  const [userValidationLoading, setUserValidationLoading] = useState<boolean>(false);
  const userInfo = useSelector(selectorUserInfo);
  const activeStep = useSelector(selectorStep);
  const dispatch = useDispatch();

  useScrollTop();
  useEffect(() => {
    ReactGA.send({ ...ReactGAOptions, title: 'Checkout: Create Account' });
    ReactPixel.track('ViewContent', { content_name: 'Checkout: Create Account' });
  }, []);

  return (
    <StyledContainer>
      <Formik
        initialValues={userInfo}
        validationSchema={SignupSchema}
        onSubmit={async values => {
          setUserValidationLoading(true);
          setUserValidation({ error: '', status: 0 });

          const error = await checkIfUserExist({ email: values.email });

          setUserValidationLoading(false);

          if (error) {
            setUserValidation(error);
            return;
          }

          dispatch(setUserInfo({ email: values.email, password: values.password }));
          dispatch(setStep(activeStep + 1));
        }}
      >
        {({ isValid, dirty, touched }) => {
          console.log(touched);
          return (
            <>
              <Form>
                <Field
                  type="email"
                  id="email"
                  name="email"
                  label="Email Address *"
                  userValidation={userValidation?.error}
                  component={Input}
                />
                {userValidation?.error && (
                  <ErrorMessage>
                    {userValidation?.error} {userValidation.status === 409 && SIGN_IN_LINK}
                  </ErrorMessage>
                )}
                <Field
                  type="password"
                  id="pass"
                  name="password"
                  label="Password (at least 8 characters) *"
                  component={Input}
                />
                <Field type="password" id="passConf" name="passwordConf" label="Confirm Password *" component={Input} />
                <Button type="submit" disabled={!(isValid && dirty) || userValidationLoading}>
                  SAVE & CONTINUE
                </Button>
              </Form>
            </>
          );
        }}
      </Formik>
    </StyledContainer>
  );
};
