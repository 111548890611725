export const COLORS = {
  oliveText: '#787d2f',
  orange: '#ecbf4b',
  grey01: '#330',
  grey02: '#333',
};

export const ANALYTICS_PAGE_TITLE = {
  page: '/checkout',
};

export const ReactGAOptions = {
  hitType: 'pageview',
  page: ANALYTICS_PAGE_TITLE.page,
};

export const REACT_GA_ID = `${process.env.REACT_APP_GA_ID}`;

export const FB_PIXEL_ID = `${process.env.REACT_APP_FB_PIXEL_ID}`;
