import styled from 'styled-components';
import theme from '../../../feachers/theme';

export const StyledInput = styled.input`
  display: block;
  width: 100%;
  height: 55px;
  padding: 8px 40px;
  margin-right: 30px;
  font-size: 18px;
  font-family: inherit;
  line-height: 1.42857143;
  color: #333333;
  vertical-align: middle;
  background-color: #ffffff;
  border: 1px solid rgba(135, 138, 62, 0.5);
  border-radius: 40px;

  @media screen and ${theme.breakpoints.mobile} {
    margin-right: 0;
    margin-bottom: 20px;
    font-size: 15px;
    padding: 8px 20px;
  }
`;
