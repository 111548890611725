import { configureStore } from '@reduxjs/toolkit';
import { userReducer } from './user/user.reducers';
import { viewReducer } from './view/view.reducers';
import { cartReducer } from './cart/cart.reducers';

export const store = configureStore({
  reducer: {
    user: userReducer,
    view: viewReducer,
    cart: cartReducer,
  },
  // middleware: () => [thunkMiddleware()],
});

export type GetState = typeof store.getState;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
