import Breakpoints, { breakpoints } from './breakpoints';

const tokens = {
  _breakpoints: () => breakpoints,
  breakpoints: Breakpoints,
};

export type Tokens = typeof tokens;

export default tokens;
