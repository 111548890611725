import React from 'react';
import { StylesButton } from './Button.parts';

interface Props {
  type?: 'submit' | 'reset' | 'button' | undefined;
  disabled?: boolean;
  filled?: boolean;
  loading?: boolean;
  onClick?: (e: React.SyntheticEvent) => void;
  children?: React.ReactNode;
}

export const Button: React.FC<Props> = ({ children, loading = false, filled = true, disabled, type, ...props }) => {
  return (
    <StylesButton type={type} loading={loading} disabled={disabled} filled={filled} {...props}>
      {loading ? 'Loading...' : children}
    </StylesButton>
  );
};
