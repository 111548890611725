import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  margin: auto;
  padding-top: 30px;
`;

export const Img = styled.img`
  width: 98px;
  height: auto;
  display: block;
`;
