import styled from 'styled-components';

export const Line = styled.span`
  display: block;
  width: 100%;
  height: auto;
  border-bottom: 1px solid #878a3e80;
  margin-top: 55px;
  margin-bottom: 45px;
  opacity: 0.5;
`;

export const CheckboxWrapper = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 40px;
`;

export const WrapperInput = styled.div`
  padding-bottom: 30px;
`;
