import styled from 'styled-components';
import theme from '../../feachers/theme';

export const Container = styled.div`
  margin-top: 25px;
  padding: 45px 30px;
  border-radius: 40px;
  border: 1px solid #878a3e;
  background: rgba(135, 138, 62, 0.2);

  @media screen and ${theme.breakpoints.screenMd} {
    padding: 25px 15px;
  }
`;

export const StyledTitle = styled.h3`
  font-size: 35px;
  margin-top: 0;
  margin-bottom: 20px;
  padding-top: 0;
  text-align: left;
  color: #787d2f;
  font-weight: 400;
  font-family: 'Mix Pudgy Panda';
  text-transform: uppercase;
  letter-spacing: 1.5px;
`;

export const Text = styled.p`
  color: #330;
  font-size: 20px;
  margin-bottom: 20px;
`;

export const ProductList = styled.ul`
  display: flex;
  flex-direction: column;
  //margin: 0;
  padding: 0;
  border-bottom: 1px solid rgba(135, 138, 62, 0.5);
`;

export const ProductItem = styled.li`
  padding-bottom: 20px;
  display: flex;
  align-items: center;
`;

export const ProductItemImg = styled.div`
  width: 95px;
  height: 63px;
  margin-right: 22px;
  overflow: hidden;
  img {
    border-radius: 15px;
    height: 100%;
  }
`;

export const ProductItemText = styled.span`
  font-size: 19px;
  text-align: left;
  color: #330;
  margin-right: 22px;
`;

export const ProductItemPrice = styled.span`
  font-size: 19px;
  color: #330;
  text-align: right;
  margin-left: auto;
`;

export const SummaryPrice = styled.div``;

export const SummaryPriceItem = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
`;

export const SummaryPriceItemTitle = styled.span`
  color: #330;
  font-size: 19px;
`;

export const SummaryPriceItemValue = styled.span`
  color: #330;
  font-size: 19px;
  text-align: right;
`;
