import React from 'react';
import ReactDOM from 'react-dom/client';
import ReactGA from 'react-ga4';
import ReactPixel from 'react-facebook-pixel';
import './index.css';
import App from './App';
import { store } from './store';
import { Provider } from 'react-redux';
import { FB_PIXEL_ID, REACT_GA_ID } from './constants';

ReactGA.initialize(REACT_GA_ID);

const options = {
  autoConfig: true,
  debug: false,
};

ReactPixel.init(FB_PIXEL_ID, undefined, options);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
    </Provider>
  </React.StrictMode>,
);
