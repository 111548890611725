import styled from 'styled-components';
import { Form } from 'formik';

export const StyledForm = styled(Form)`
  max-width: 660px;
  margin: auto;
`;

export const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 40px;
`;
