import ReactModal from 'react-modal';
import { useEffect, useState } from 'react';
import type { ModalProp } from './Modal.types';
import { CloseBtn, StyledModal, Title } from './Modal.parts';

export const Modal = ({ children, title, isOpen, closeModal, afterOpenModal, width }: ModalProp) => {
  const [modalIsOpen, setIsOpen] = useState(isOpen);

  const customStyles = {
    overlay: {
      background: 'rgba(120, 125, 47, 0.30)',
      zIndex: 2,
    },
    content: {
      width: width,
    },
  };

  useEffect(() => {
    setIsOpen(isOpen);
  }, [isOpen]);

  ReactModal.setAppElement('#root');
  return (
    <StyledModal
      isOpen={modalIsOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={closeModal}
      style={customStyles}
      contentLabel="Modal"
    >
      <CloseBtn onClick={closeModal} />
      <Title>{title}</Title>
      {children}
    </StyledModal>
  );
};
