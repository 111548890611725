import { CSSChunk } from '../../theme';

export const breakpoints = {
  mobile: 375,
  tabletSmall: 480,
  tablet: 768,
  tabletLandscape: 1024,
  screenMd: 992,
  screenLg: 1280,
  screenXl: 1440,
};

class Breakpoints {
  get mobileXs(): CSSChunk {
    return `(max-width: calc(${breakpoints.mobile}px))`;
  }
  get mobileSm(): CSSChunk {
    return `(max-width: calc(${breakpoints.tabletSmall}px))`;
  }
  get mobile(): CSSChunk {
    return `(max-width: calc(${breakpoints.tablet}px - 1px))`;
  }
  get tablet(): CSSChunk {
    return `(max-width: calc(${breakpoints.tabletLandscape}px))`;
  }
  get tabletLandscape(): CSSChunk {
    return `(max-width: ${breakpoints.tabletLandscape}px)`;
  }
  get screenMd(): CSSChunk {
    return `(max-width: ${breakpoints.screenMd}px)`;
  }
  get screenLg(): CSSChunk {
    return `(max-width: ${breakpoints.screenLg}px)`;
  }
  get screenXl(): CSSChunk {
    return `(max-width: ${breakpoints.screenXl}px)`;
  }
}

export default new Breakpoints();
