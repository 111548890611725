import styled from 'styled-components';
import ReactModal from 'react-modal';
import CloseIcon from '../../assets/icons/close.svg';
import theme from '../../feachers/theme';

export const StyledModal = styled(ReactModal)`
  position: absolute;
  width: 790px;
  padding: 20px 20px 60px 20px;
  border-radius: 40px;
  background: #fbfcf5;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media screen and ${theme.breakpoints.screenMd} {
    width: 100% !important;
    padding: 10px 10px 40px 10px;
  }
`;

export const CloseBtn = styled.button`
  position: absolute;
  right: 20px;
  top: 20px;
  height: 60px;
  width: 60px;
  padding: 0;
  background-image: url(${CloseIcon});
  background-size: cover;
  background-color: transparent;
  border: none;
  cursor: pointer;

  @media screen and ${theme.breakpoints.screenMd} {
    top: 0px;
    right: 0;
  }
`;

export const Title = styled.p`
  margin-top: 50px;
  text-align: center;
  font-weight: 400;
  font-size: 30px;
  font-family: Mix Pudgy Panda;
  text-transform: uppercase;
  letter-spacing: 1.5px;

  @media screen and ${theme.breakpoints.screenMd} {
    font-size: 26px;
  }
`;

export const ButtonWrap = styled.div`
  display: flex;
  justify-content: space-between;
  margin: auto;
  width: 80%;
  button {
    width: 47%;
  }

  @media screen and ${theme.breakpoints.screenMd} {
    flex-wrap: wrap;
    width: 100%;

    button {
      margin-bottom: 10px;
      width: 100%;
    }
  }
`;

export const ModalContentWrap = styled.div`
  max-width: 100%;
  width: 580px;
  margin: auto;
  button {
    width: 47%;
  }

  @media screen and ${theme.breakpoints.screenMd} {
    button {
      width: 100%;
    }
  }
`;

export const ModalContentText = styled.p`
  margin-bottom: 40px;
  text-align: center;
  font-family: Meloso;
  font-size: 18px;
  font-weight: 600;
`;

export const ModalContentTextNote = styled.p`
  padding-bottom: 30px;
  color: #878a3e;
  text-align: center;
  font-family: Meloso;
  font-size: 15px;
  font-style: normal;
`;
