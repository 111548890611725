import { useEffect, useState } from 'react';
import { getWindowSize } from '../helpers/getWindowSize';

export const useGetWindowSize = (): { innerWidth: number; innerHeight: number } => {
  const [windowSize, setWindowSize] = useState(getWindowSize());

  useEffect(() => {
    const handleWindowResize = () => {
      setWindowSize(getWindowSize());
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  return windowSize;
};
