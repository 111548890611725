import styled from 'styled-components';
import theme from '../../feachers/theme';

export const FormRow = styled.div`
  padding-bottom: 40px;
`;

export const TermsBlock = styled.div`
  background-color: rgba(164, 175, 104, 0.2);
  border-radius: 40px;
  margin-bottom: 40px;
  padding: 40px;

  @media screen and ${theme.breakpoints.screenMd} {
    padding: 20px;
  }
`;

export const TermsBlockTitle = styled.p`
  margin: 0;
  padding-bottom: 30px;
  color: #333300;
  font-weight: 500;
  line-height: 26px;
  font-size: 19px;
`;
