import { Container, EditBtn, Text } from './EditBlock.parts';
import React from 'react';
import { StyledLabel } from '../Input/Input.parts';

interface Props {
  text: string;
}
export const EditBlock: React.FC<Props> = ({ text }) => {
  return (
    <>
      <StyledLabel>Your current delivery address:</StyledLabel>
      <Container>
        <Text>{text}</Text>
        {/*<EditBtn onClick={onClick}>Edit</EditBtn>*/}
      </Container>
    </>
  );
};
