import styled from 'styled-components';
import done from '../../assets/icons/done-1.svg';

type StepContainerProps = {
  width: string;
};

type StepStyleProps = {
  isFilled: boolean;
  isDone: boolean;
};

type StepLabelProps = {
  isActive: boolean;
  isDone: boolean;
};

type MobileStepItemProps = {
  isActive: boolean;
};

export const MainContainer = styled.div`
  width: 100%;
  max-width: 1110px;
  margin: 0 auto;
  padding-top: 55px;
  padding-bottom: 40px;
`;

export const StepContainer = styled.div<StepContainerProps>`
  display: flex;
  justify-content: space-between;
  margin-top: 45px;
  margin-right: 50px;
  margin-left: 50px;
  position: relative;
  :before {
    content: '';
    position: absolute;
    background: rgba(164, 175, 104, 0.3);
    height: 2px;
    width: 99%;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
  }
  :after {
    content: '';
    position: absolute;
    background: #787d2f;
    height: 2px;
    width: ${({ width }) => width};
    top: 50%;
    transition: 0.4s ease;
    transform: translateY(-50%);
    left: 0;
  }
`;

export const StepWrapper = styled.div`
  display: flex;
  position: relative;
  z-index: 1;
  height: 27px;
  width: 27px;
`;

export const StepStyle = styled.div<StepStyleProps>`
  position: relative;
  width: ${({ isFilled }) => (isFilled ? '27px' : '20px')};
  height: ${({ isFilled }) => (isFilled ? '27px' : '20px')};
  color: rgba(135, 138, 62, 0.5);
  border-radius: 50%;
  background-color: ${({ isFilled }) => (isFilled ? '#787d2f' : '#fff')};
  border: 2px solid #787d2f;
  transition: 0.4s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  :after {
    opacity: ${({ isDone }) => (isDone ? 1 : 0)};
    position: absolute;
    content: '';
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    background-image: url(${done});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    width: 13px;
    height: 13px;
    transition: 0.4s ease;
  }
`;

export const StepsLabelContainer = styled.div`
  position: absolute;
  top: -35px;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 205px;
  text-align: center;
`;

export const StepLabel = styled.span<StepLabelProps>`
  font-size: 16px;
  font-family: Mix Pudgy Panda;
  color: #787d2f;
  transition: opacity 0.4s;
  opacity: ${({ isDone, isActive }) => {
    if (isDone) {
      return 0.7;
    } else if (!isDone && !isActive) {
      return 0.5;
    } else if (isActive) {
      return 1;
    }
  }};
`;

export const ButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 -15px;
  margin-top: 100px;
`;

export const ButtonStyle = styled.button`
  border-radius: 4px;
  border: 0;
  background: red;
  color: #ffffff;
  cursor: pointer;
  padding: 8px;
  width: 90px;
  :active {
    transform: scale(0.98);
  }
  :disabled {
    background: #f3e7f3;
    color: #000000;
    cursor: not-allowed;
  }
`;

export const MobileStepWrapper = styled.ul`
  display: flex;
  justify-content: space-between;
  margin: 0;
  padding: 0;
`;

export const MobileStepItem = styled.li<MobileStepItemProps>`
  display: flex;
  align-items: center;
  width: 40px;
  height: 40px;
  margin: 0 10px;
  border-radius: 50%;
  background-color: rgba(120, 125, 47, 1);
  list-style: none;
  opacity: ${({ isActive }) => (isActive ? 1 : 0.4)};
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
`;

export const MobileStepNumber = styled.span`
  color: #f5f5f5;
  margin: auto;
  font-family: 'Mix Pudgy Panda';
  font-size: 22px;
`;
