import { createGlobalStyle } from 'styled-components';
import { COLORS } from './constants';
import theme from './feachers/theme';

export const GlobalStyles = createGlobalStyle`
  * {
    box-sizing: border-box;
  }
  
  body {
    max-width: 1380px;
    margin: 0 auto;
    padding: 0 20px;
  }
  
  html {
    font-size: 18px;
    font-family: 'Meloso';
    color: #787D2F; 
    background: #edeae6;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    --color-olive-text: ${COLORS.oliveText};
    --color-grey-01: ${COLORS.grey01};
    --color-grey-02: ${COLORS.grey02};
    --color-primary: ${COLORS.grey02};
    --color-orange: ${COLORS.orange};
    --typesize--small: 14px;
    --typesize--default: 18px;
    --typesize--medium: 17px;
    --typeweight--default: 400;
    --typeweight--bold: 700;
    @media screen and ${theme.breakpoints.mobileSm} {
      font-size: 16px;
    }
  }
  a {
    color: #787D2F;
  }
  img {
    display: block;
    width: 100%;
    margin: auto;
  }
`;
