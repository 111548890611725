import React from 'react';
import { Container, ErrorMessage, StyledInput, StyledLabel } from './Input.parts';

interface Props {
  id: string;
  name: string;
  label: string;
  userValidation?: string;
  form?: {
    touched: any;
    errors: [];
  };
}
// @ts-ignore
export const Input = ({ field, label, name, id, userValidation, form: { touched, errors }, ...props }: Props) => {
  const hasError = (touched[field.name] && errors[field.name]) || userValidation;
  return (
    <Container>
      <StyledLabel htmlFor={id}>{label}</StyledLabel>
      <StyledInput error={hasError} {...props} {...field} />
      {hasError && <ErrorMessage>{errors[field.name]}</ErrorMessage>}
    </Container>
  );
};
