import styled from 'styled-components';
import IconRemove from '../../assets/icons/close-2.svg';

type ItemProps = {
  isActive?: boolean;
};
export const Wrapper = styled.div`
  padding-top: 20px;
`;

export const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  margin-bottom: 30px;
`;

export const Item = styled.li<ItemProps>`
  position: relative;
  padding-top: 6px;
  padding-bottom: 6px;
  padding-right: ${({ isActive }) => (isActive ? ' 38px' : '18px')};
  padding-left: 18px;
  border-radius: 40px;
  margin-right: 20px;
  margin-bottom: 20px;
  list-style: none;
  border: 1px solid ${({ isActive }) => (isActive ? ' #333300' : 'rgba(51, 51, 0, 0.66)')};
  color: ${({ isActive }) => (isActive ? ' #333300' : 'rgba(51, 51, 0, 0.66)')};
  background: ${({ isActive }) => (isActive ? ' #fff' : 'transparent')};
  cursor: pointer;
`;

export const RemoveIcon = styled.span`
  display: block;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  width: 19px;
  height: 20px;
  background-image: url(${IconRemove});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;

export const Text = styled.p`
  font-size: 16px;
  line-height: 24px;
`;

export const Textarea = styled.textarea`
  display: block;
  width: 100%;
  padding: 8px 40px;
  font-size: 18px;
  line-height: 1.42857143;
  color: #333300;
  vertical-align: middle;
  background-color: #ffffff;
  border: 1px solid rgba(135, 138, 62, 0.5);
  border-radius: 40px;
  font-family: Meloso;
  :hover,
  :focus {
    box-shadow: 1px 1px 10px 0px rgba(135, 138, 62, 0.7);
    outline: none;
  }
  ::placeholder {
    color: rgba(51, 51, 0, 0.5);
  }

  ::-ms-input-placeholder {
    color: rgba(51, 51, 0, 0.3);
  }
`;
