import axios from 'axios';
import { API_URL } from '../../store/api/api.const';

export const getProducts = async (postcode: string) => {
  try {
    const res = await axios.get(`${API_URL}/product/`, {
      params: {
        postcode,
      },
    });
    return res.data;
  } catch (error) {
    console.error(error);
    alert('Something went wrong. Please try again later');
    throw Error();
  }
};

export const getExclusions = async () => {
  try {
    const res = await axios.get(`${API_URL}/product/exclusions/`);
    return res.data;
  } catch (error) {
    console.error(error);
    alert('Something went wrong. Please try again later');
    throw Error();
  }
};
