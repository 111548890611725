import React from 'react';
import './fonts.css';
import { GlobalStyles } from './globalStyles';
import { ProgressBar } from './components/ProgressBar';
import { AddressChecker } from './sections/AddressChecker';
import { Products } from './sections/Products';
import { OrderDetails } from './sections/OrderDetails';
import { useSelector } from 'react-redux';
import { selectorStep } from './store/view/view.selectors';
import { Root } from './components/Root/Root';
import { Logo } from './components/Logo';

function App() {
  const activeStep = useSelector(selectorStep);
  return (
    <Root>
      <GlobalStyles />
      <Logo />
      <ProgressBar />
      {activeStep === 1 && <AddressChecker />}
      {activeStep === 2 && <Products />}
      {activeStep >= 3 && <OrderDetails />}
    </Root>
  );
}

export default App;
