import {
  StepWrapper,
  StepContainer,
  MainContainer,
  StepsLabelContainer,
  StepLabel,
  StepStyle,
  ButtonStyle,
  ButtonsContainer,
  MobileStepWrapper,
  MobileStepItem,
  MobileStepNumber,
} from './ProgressBar.parts';
import { useDispatch, useSelector } from 'react-redux';
import { setStep } from '../../store/view/view.reducers';
import { selectorStep } from '../../store/view/view.selectors';
import { useGetWindowSize } from '../../hooks/useGetWindowSize';
import { breakpoints } from '../../feachers/theme/tokens/breakpoints';

const steps = [
  {
    label: 'ADDRESS CHECKER',
    step: 1,
  },
  {
    label: 'YOUR FIRST BOX',
    step: 2,
  },
  {
    label: 'ACCOUNT DETAILS',
    step: 3,
  },
  {
    label: 'DELIVERY DETAILS',
    step: 4,
  },
  {
    label: 'PAYMENT DETAILS',
    step: 5,
  },
];

export const ProgressBar = () => {
  const dispatch = useDispatch();
  const activeStep = useSelector(selectorStep);
  const { innerWidth } = useGetWindowSize();

  const nextStep = () => {
    dispatch(setStep(activeStep + 1));
  };

  const prevStep = () => {
    dispatch(setStep(activeStep - 1));
  };

  const totalSteps = steps.length;

  const width = `${(100 / (totalSteps - 1)) * (activeStep - 1)}%`;

  return (
    <MainContainer>
      {innerWidth > breakpoints.screenMd ? (
        <StepContainer width={width}>
          {steps.map(({ step, label }) => (
            <StepWrapper key={step}>
              <StepsLabelContainer>
                <StepLabel isActive={activeStep === step} isDone={activeStep > step}>
                  {label}
                </StepLabel>
              </StepsLabelContainer>
              <StepStyle isFilled={activeStep >= step} isDone={activeStep > step}></StepStyle>
            </StepWrapper>
          ))}
        </StepContainer>
      ) : (
        <MobileStepWrapper>
          {steps.map(({ step }) => (
            <MobileStepItem key={step} isActive={activeStep === step}>
              <MobileStepNumber>{step}</MobileStepNumber>
            </MobileStepItem>
          ))}
        </MobileStepWrapper>
      )}

      {/*<ButtonsContainer>*/}
      {/*  <ButtonStyle onClick={prevStep} disabled={activeStep === 1}>*/}
      {/*    Previous*/}
      {/*  </ButtonStyle>*/}
      {/*  <ButtonStyle onClick={nextStep} disabled={activeStep === totalSteps}>*/}
      {/*    Next*/}
      {/*  </ButtonStyle>*/}
      {/*</ButtonsContainer>*/}
    </MainContainer>
  );
};
