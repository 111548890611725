import axios from 'axios';
import { API_URL } from '../../store/api/api.const';

type Props = {
  email: string;
  phone?: string;
};

export type ErrorMessageType = { status: number; error: string };

export const checkIfUserExist = async ({ email, phone = '' }: Props): Promise<ErrorMessageType | undefined> => {
  try {
    await axios.post(`${API_URL}/account/validate/`, { email, phone });
  } catch (error: any) {
    console.log(error);
    if (error.response.status === 409 || error.response.status === 400) {
      return {
        status: error.response.status,
        error: error.response?.data?.error || '',
      };
    }
  }
};
