import styled from 'styled-components';
import theme from '../../feachers/theme';

type Props = {
  showOrderSummary: boolean;
};

export const Container = styled.div<Props>`
  display: flex;
  justify-content: space-between;

  @media screen and ${theme.breakpoints.screenMd} {
    flex-wrap: wrap;
    flex-direction: column-reverse;
  }
`;

export const FormWrap = styled.div`
  width: 50%;

  @media screen and ${theme.breakpoints.screenMd} {
    width: 100%;
  }
`;

export const OrderWrap = styled.div`
  width: 43%;
  @media screen and ${theme.breakpoints.screenMd} {
    width: 100%;
  }
`;
