import { CheckboxInput, Container, Label } from './Checkbox.parts';

type Props = {
  id: string;
  label: string;
  name: string;
  value: string;
  onClick: () => void;
  type?: 'radio' | 'checkbox';
  checked?: boolean;
  labelColor?: string;
  field?: any;
  form?: any;
};

export const Checkbox = ({
  id,
  label,
  name,
  value,
  onClick,
  type = 'checkbox',
  checked,
  labelColor = '#101010',
}: Props) => {
  return (
    <Container>
      <CheckboxInput onClick={onClick} id={id} type={type} name={name} value={value} checked={checked} />
      <Label htmlFor={id} labelColor={labelColor}>
        {label}
      </Label>
    </Container>
  );
};

export const CheckboxFormik = ({
  id,
  label,
  name,
  value,
  onClick,
  type = 'checkbox',
  checked,
  labelColor = '#101010',
  field,
  form,
  ...props
}: Props) => {
  return (
    <Container>
      <CheckboxInput
        onClick={onClick}
        id={id}
        type={type}
        name={name}
        value={value}
        checked={checked}
        {...props}
        {...field}
      />
      <Label htmlFor={id} labelColor={labelColor}>
        {label}
      </Label>
    </Container>
  );
};
