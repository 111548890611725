import styled from 'styled-components';
import DoneIcon from '../../assets/icons/done.svg';
import theme from '../../feachers/theme';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FormWrap = styled.div`
  width: 50%;

  @media screen and ${theme.breakpoints.screenMd} {
    width: 100%;
  }
`;

export const OrderWrap = styled.div`
  width: 43%;

  @media screen and ${theme.breakpoints.screenMd} {
    display: none;
  }
`;

export const InfoBox = styled.div`
  background-color: rgba(164, 175, 104, 0.2);
  border-radius: 40px;
  padding: 30px 35px;
  margin-bottom: 35px;
`;

export const InfoBoxText = styled.div`
  color: #330;
  font-weight: 500;
  padding-bottom: 20px;
  @media screen and ${theme.breakpoints.mobile} {
    font-size: 16px;
  }
`;

export const InfoBoxIcon = styled.span`
  position: relative;
  display: block;
  padding-left: 40px;
  font-size: 14px;
  :after {
    position: absolute;
    content: '';
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    background-image: url(${DoneIcon});
    background-position: center;
    background-size: contain;
    width: 25px;
    height: 25px;
  }
`;
