import { StyledInput } from './Input.parts';
import React from 'react';

type Props = {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  value: string;
  placeholder?: string;
};
export const Input = ({ onChange, value, placeholder }: Props) => {
  return <StyledInput autoComplete="street-address" onChange={onChange} value={value} placeholder={placeholder} />;
};
