import styled from 'styled-components';
import IconTitle from '../../assets/icons/step.svg';
import theme from '../../feachers/theme';

export const Wrapper = styled.div``;

export const ContainerHead = styled.div`
  max-width: 1400px;
  margin: auto;
  position: relative;
  padding-bottom: 55px;
  text-align: center;
`;

export const Subtitle = styled.h3`
  font-size: 22px;
  color: #787d2f;
  font-family: 'Mix Pudgy Panda';
  text-transform: uppercase;
  font-weight: 400;
  letter-spacing: 1px;
  opacity: 0.75;
  padding-bottom: 10px;
`;

export const Text = styled.p`
  color: #4b4d22;
  margin-bottom: 20px;
`;

export const Icon = styled.div`
  position: absolute;
  left: 0;
  top: 25px;
  width: 133px;
  height: 179px;
  img {
    display: block;
  }
  @media screen and ${theme.breakpoints.screenMd} {
    display: none;
  }
`;

export const ContainerList = styled.ul`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 0;
  margin: 0 -15px;
`;

export const ProductListTitle = styled.h3`
  display: flex;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  margin-bottom: 50px;
  width: max-content;
  padding: 10px 20px;
  border-radius: 40px;
  background: #fbfcf5;
  color: #787d2f;
  font-family: Mix Pudgy Panda;
  font-size: 30px;
  font-style: normal;
  font-weight: 400;
  line-height: 40px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  @media screen and ${theme.breakpoints.screenMd} {
    font-size: 25px;
  }
`;

export const TitleIcon = styled.span`
  position: relative;
  margin-right: 20px;
  width: 40px;
  height: 40px;
  span {
    position: absolute;
    left: 50%;
    top: 50%;
    color: #fff;
    transform: translate(-50%, -50%);
  }
  &:before {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-image: url(${IconTitle});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }
`;
