import axios from 'axios';
import { API_URL } from '../../store/api/api.const';
import { PaymentFormTypes } from './PaymentForm.types';

type Checkout = {
  URL: string;
};

export const postCheckout = async (data: PaymentFormTypes): Promise<Checkout | false> => {
  try {
    const res = await axios.post(`${API_URL}/account/checkout/`, data);
    return res.data;
  } catch (error: any) {
    if (error.response.status === 400) {
      alert(error.response?.data?.error);

      if (window.top) {
        window.top.location.href = 'https://wonkybox.nz/login';
      } else {
        window.location.href = 'https://wonkybox.nz/login';
      }

      return false;
    }

    console.error(error);
    alert('Something went wrong. Please try again later');
    return false;
  }
};
