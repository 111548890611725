import styled from 'styled-components';

import Arrow from '../../assets/icons/down-arrow.svg';
import theme from '../../feachers/theme';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 20px;
`;

export const Label = styled.label`
  font-size: 18px;
  font-weight: 500;
`;

export const StyledSelect = styled.select`
  width: 100%;
  max-width: 237px;
  background-color: #fff;
  padding: 10px 20px;
  border: 1px solid #333300;
  border-radius: 20px;
  color: #787d2f;
  font-size: 18px;

  // /* Arrow */
  appearance: none;
  background-image: url(${Arrow});
  background-repeat: no-repeat;
  background-position: right 15px top 50%;
  background-size: 15px auto;
  @media screen and ${theme.breakpoints.mobileSm} {
    max-width: 192px;
  }
`;
