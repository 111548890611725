import { ExclusionsToSelect } from './Product.types';
import { Exclusion } from '../../sections/Products/Products.types';

export const adaptToSelect = (input: Exclusion[]): ExclusionsToSelect[] => {
  return input.map(item => ({
    value: item.id,
    label: item.name,
  }));
};

export const adaptFromSelect = (input: ExclusionsToSelect[]): Exclusion[] => {
  return input.map(item => ({
    id: item.value,
    name: item.label,
  }));
};
