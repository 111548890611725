import styled from 'styled-components';
import { BaseInput } from '../Input/Input.parts';
import IconClose from '../../assets/icons/close-2.svg';
import theme from '../../feachers/theme';

export const Container = styled.div`
  margin-bottom: 30px;
`;

export const ContainerInput = styled.div`
  position: relative;
  display: flex;
  @media screen and ${theme.breakpoints.screenMd} {
    flex-wrap: wrap;
  }
`;

export const DiscountInput = styled(BaseInput)`
  margin-right: 30px;
  @media screen and ${theme.breakpoints.screenMd} {
    margin-right: 0;
    margin-bottom: 10px;
  }
`;

export const Error = styled.p`
  position: absolute;
  left: 0;
  bottom: -20px;
  margin: 0;
  margin-left: 45px;
  color: red;
  font-size: 14px;
`;

export const DiscountList = styled.ul`
  padding: 0;
`;

export const DiscountItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 40px;
  background: #fbfcf5;
  padding: 14px 40px;
  margin-bottom: 20px;
  &:first-child {
    margin-top: 30px;
  }
`;

export const DiscountItemWrap = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DiscountItemCode = styled.span`
  color: #787d2f;
  font-size: 19px;
  font-weight: 700;
`;

export const DiscountItemText = styled.span`
  color: #330;
  font-size: 19px;
  font-weight: 400;
`;

export const DiscountItemBtn = styled.button`
  width: 34px;
  height: 34px;
  background-image: url(${IconClose});
  background-position: center;
  background-size: contain;
  background-color: transparent;
  background-repeat: no-repeat;
  border: none;
  cursor: pointer;
`;
