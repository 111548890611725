import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import type { View } from './view.dto';

const initialState: View = {
  step: 1,
};

const viewSlice = createSlice({
  name: 'view',
  initialState: initialState,
  reducers: {
    setStep(state, { payload }: PayloadAction<number>) {
      state.step = payload;
    },
  },
});

export const { setStep } = viewSlice.actions;

export const viewReducer = viewSlice.reducer;
