import axios from 'axios';
import { API_URL } from '../../store/api/api.const';
import { AddToWaitingList } from './WaitingListForm.types';

export const addToWaitingList = async ({ email, postcode, name }: AddToWaitingList) => {
  try {
    const res = await axios.post(`${API_URL}/waiting_list/add/`, {
      email,
      postcode,
      name,
    });
    return res.data;
  } catch (error) {
    console.error(error);
    alert('Something went wrong. Please try again later');
    return false;
  }
};
