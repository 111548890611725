import styled from 'styled-components';

export const StyledContainer = styled.div``;

export const ErrorMessage = styled.span`
  display: inline-block;
  color: #ea4335;
  font-size: 14px;
  padding-bottom: 10px;
  a {
    font-weight: bold;
  }
`;
