import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 18px 40px;
  margin-bottom: 30px;
  border: 1px solid #787d2f;
  border-radius: 40px;
  background-color: #ede2a4;
`;

export const Text = styled.span`
  display: block;
  padding-bottom: 18px;
  color: #330;
  font-size: 17px;
  font-weight: 500;
  &:last-of-type {
    padding-bottom: 0;
  }
`;

export const EditBtn = styled.button`
  align-items: center;
  background: transparent;
  color: #330;
  font-size: 17px;
  font-weight: 500;
  text-decoration: underline;
  border: none;
  padding-left: 20px;
  padding-right: 0;
  margin: 0;
  cursor: pointer;
`;
